"use client";
import React from 'react'
import { AiFillGithub } from "react-icons/ai";
import { IoMdInformationCircle } from "react-icons/io";

function Skills() {
  return (
    <main>
      <section id="education" className="">
          <div className="pt-16 text-black dark:text-white transition-colors duration-1000">
          <h1 className='text-3xl font-bold text-black dark:text-white transition-colors duration-1000'>Education</h1>
          <div className='py-5 flex gap-5 flex-wrap'>

          <div className="card card-compact w-full bg-zinc-200 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
              <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
                  <h2 className="card-title">University of California, San Diego</h2>
                  <i className="-mt-3">B.S. in Computer Science, Business Minor</i>
                  <section>
                    <h3 className="text-md font-semibold">Computer Science Relevant Coursework:</h3>
                  {("Java DS and OOP, Discrete Mathematics, Computer Organization & Systems Programming, Linear Algebra, Advanced DS, Theory of Computability, Software Engineering, Components and Design Techniques for Digital Systems, Intro to Computer Architecture, AI: Search and Reasoning, Principles of Computer Operating Systems, Machine Learning: Learning Algorithms, Statistical NLP, Web Client Languages, Computer Graphics").split(', ').map((str) => (
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">{str}</p>
                  ))}
                  </section>
                  <section>
                    <h3 className="text-md font-semibold">Business Relevant Coursework:</h3>
                  {("Personal Ethics at Work, Principles of Accounting").split(', ').map((str) => (
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">{str}</p>
                  ))}
                  </section>
                  <div className="card-actions justify-end">
                      <p>Expected Graduation: March 2026</p>  
                </div>
              </div>
            </div>

          </div>
          </div>
        </section>
      <section id="experience" className="">
          <div className="pt-16 text-black dark:text-white transition-colors duration-1000">
          <h1 className='text-3xl font-bold text-black dark:text-white transition-colors duration-1000'>Work Experience</h1>
          <div className='py-5 flex gap-5 flex-wrap'>

          <div className="card card-compact w-full bg-zinc-200 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
              <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
                  <h2 className="card-title">Vetscribe by Rito Labs</h2>
                  <i className="-mt-3">Software Engineering Intern</i>
                  <section>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Developing a full stack web and mobile application using AWS Amplify, Expo, and React.</p>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Improved a live recording waveform and toast notifications to give feedback and status to users after performing actions.</p>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Created key AWS lambda functions to help users efficiently delete sections of and copy their templates.</p>
                  </section>
                  <div className="card-actions justify-between">
                    <div className='gap-3 flex flex-wrap'>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">Expo</div> 
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">AWS Amplify</div>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">React Native</div>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">GraphQL</div>  
                    </div>  
                    <p>July 2024 - Present</p>      
                </div>
              </div>
            </div>

            <div className="card card-compact w-full bg-zinc-200 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
              <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
                  <h2 className="card-title">System Energy Efficiency (SEE) Lab UC San Diego</h2>
                  <i className="-mt-3">Student Research Assistant</i>
                  <section>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Using Hyperdimensional computing (HDC) for efficient mass spectrometry data clustering.</p>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Installing and running mass spectrometry clustering tools on CUDA-1 and CUDA-2 servers and collecting data on speed and accuracy.</p>
                  </section>
                  <div className="card-actions justify-between">
                    <div className='gap-3 flex flex-wrap'>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">Docker</div> 
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">Hyperdimensional Computing</div>
                    </div>  
                    <p>May 2024 - Dec 2024</p>       
                </div>
              </div>
            </div>

            

          </div>
          </div>
        </section>

      <section className="min-h-screen">
        <div className="pt-16 text-black dark:text-white transition-colors duration-1000">
          <h1 id="skills" className="text-3xl font-bold mb-2">Technical Skills</h1>
          {/* <div className="flex gap-3">(
            <span className="indicator-item badge bg-pink-500 text-black font-semibold">recently used</span> 
            <span className="indicator-item badge bg-sky-400 text-black font-semibold">learning</span> 
            )
          </div> */}
          <section>
            <h2 className="text-2xl">Languages</h2>
            <div className="p-2 gap-2 flex flex-wrap">
              {[
                ['Java',       ''],
                ['Python',     ''],
                ['C/C++',      'hidden'],
                ['C#',         'hidden'],
                ['ARM/MIPS',   'hidden'],
                ['Bash',       'hidden'],
                ['HTML/CSS',   ''],
                ['Javascript', '',],
                ['SystemVerilog', 'hidden',],
                ['OpenGL', 'hidden',],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-200 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  <span className={`${highlight} indicator-item badge bg-pink-500 border-pink-500`}></span> 
                  <h2 className="text-sm text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </section>
          <section>
            <h2 className="text-2xl">Tools & Frameworks</h2>
            <div className="p-2 gap-2 flex flex-wrap">
              {[
                ['JUnit',              'hidden'],
                ['GDB',                'hidden'],
                ['Quart/Flask',        'hidden'],
                ['React',              ''],
                ['TailwindCSS',        ''],
                ['Firebase',           'hidden'],
                ['Figma',              'hidden'],
                ['VSCode',             'hidden'],
                ['Android Studio',     'hidden'],
                ['Vercel',             'hidden'],
                ['Windows',            'hidden'],
                ['Ubuntu',             'hidden'],
                ['macOS',              'hidden'],
                ['Git',                'hidden'],
                ['Swift',              'hidden'],
                ['Unity',              'hidden'],
                ['ModelSim',           'hidden'],
                ['Quartus',            'hidden'],
                ['AWS Amplfiy',        'hidden'],
                ['GraphQL',            'hidden'],
                ['Expo',               'hidden'],
                ['Linear',             'hidden'],
                ['Docker',             'hidden'],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-200 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  {/* <span className={`${learning} indicator-item badge bg-sky-400`}></span>  */}
                  <span className={`${highlight} indicator-item badge bg-pink-500 border-pink-500`}></span> 
                  <h2 className="text-sm text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </section>
          <section>
            <h2 className="text-2xl">Techniques</h2>
            <div className="p-2 gap-2 flex flex-wrap">
              {[
                ['Agile software process', ''],
                ['Behavior Driven Development (BDD)', 'hidden'],
                ['Unit Testing', 'hidden'],
                ['Object Mocking', 'hidden'],
                ['Continuous Integration', 'hidden'],
                ['Single Responsibility Principle (SRP)', 'hidden'],
                ['Dependency Inversion', 'hidden'],
                ['Open-Closed Principle', 'hidden'],
                ['Design Patterns (Strategy, Observer, MVP, Abstract Factory, Builder', 'hidden'],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-200 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  <span className={`${highlight} indicator-item badge bg-pink-500 border-pink-500`}></span> 
                  <h2 className="text-sm text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </section>
          
          
          

        </div>
      </section>
    </main>
  )
}

export default Skills